<template>
  <div class="section-container">
    <Table
      ref="tableRef"
      :params="params"
      :request="getData"
      @clear="clearData"
    >
      <template #search="{query}">
        <el-form-item>
          <el-button type="primary" @click="exportData(query)"
            >表格导出</el-button
          >
        </el-form-item>
      </template>
      <el-table-column type="index" label="序号" width="60px" />
      <el-table-column prop="orderNo" label="订单号" />
      <el-table-column prop="userName" label="姓名" />
      <el-table-column prop="userLabel" label="用户标签" >
        <template #default="{row}">
          {{ row.userLabel == 1 ? '内部用户' : '普通用户' }}
        </template>
      </el-table-column>
      <el-table-column prop="phoneNumber" label="手机号"></el-table-column>
      <el-table-column prop="area" label="收件地区" >
        <template #default="{row}">
          {{ row.district && getLocation(row.province, row.city, row.district) }}
        </template>
      </el-table-column>
      <el-table-column prop="location" label="收件地址" />
      <el-table-column prop="remark" label="用户留言" >
        <template #default="{row}">
          <el-popover
            placement="top-start"
            :width="320"
            trigger="hover"
          >
            <template #default>
              {{row.remark}}
            </template>
            <template #reference>
              <span class="one-line-text">{{row.remark}}</span>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="兑换时间"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="score"
        label="扣除积分"
        sortable="custom"
      ></el-table-column>
      <el-table-column prop="name" label="兑换商品"></el-table-column>
      <el-table-column
        prop="processTime"
        label="处理时间"
        sortable="custom"
      ></el-table-column>
      <el-table-column prop="processStatus" label="状态">
        <template #default="{ row }">
          {{ row.processStatus ? '已处理' : '未处理' }}</template
        >
      </el-table-column>
      <el-table-column prop="processRemark" label="备注"></el-table-column>
      <el-table-column label="操作" width="150px" fixed="right">
        <template #default="{ row }">
          <el-button
            v-if="row.processStatus"
            type="text"
            text
            @click="checkDetail(row)"
            >查看
          </el-button>
          <el-button
            v-else
            type="primary"
            plain
            size="mini"
            @click="handleDetail(row)"
            >去处理</el-button
          >
        </template>
      </el-table-column>
    </Table>
    <el-dialog
      title="处理"
      top="5vh"
      v-model="dialogFormVisible"
      destroy-on-close
      :modal="true"
      :append-to-body="true"
    >
      <el-form
        ref="detailformRef"
        class="dialog-form"
        :model="detailform"
        :rules="detailformRules"
        label-width="100px"
      >
        <el-form-item label="兑换商品" prop="name">
          {{ detailform.name }}
        </el-form-item>
        <el-form-item label="审核结果" prop="result">
          <el-radio-group v-model="detailform.result">
            <el-radio :label="true">通过</el-radio>
            <el-radio :label="false">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="detailform.result"
          label="填写运单号"
          prop="expressOrderNo"
        >
          <el-input
            v-model="detailform.expressOrderNo"
            placeholder="填写运单号"
            clearable
          />
        </el-form-item>
        <el-form-item label="处理时间" prop="processTime">
          <el-date-picker
            v-model="detailform.processTime"
            type="datetime"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            placeholder="处理时间"
            clearable
          />
        </el-form-item>
        <el-form-item label="备注" prop="processRemark">
          <el-input
            v-model="detailform.processRemark"
            placeholder="备注（不大于20个字符）"
            clearable
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" size="small" @click="onFormSubmit"
          >确认</el-button
        >
      </template>
    </el-dialog>
    <el-dialog
      title="详情"
      top="5vh"
      v-model="dialogDetailVisible"
      destroy-on-close
      :modal="true"
      :append-to-body="true"
    >
      <el-form class="dialog-form" :model="detailData" label-width="100px">
        <el-form-item label="兑换商品:" prop="name">{{
          detailData.name
        }}</el-form-item>
        <el-form-item label="运单号:" prop="expressOrderNo">{{
          detailData.expressOrderNo
        }}</el-form-item>
        <el-form-item label="处理时间:" prop="processTime">{{
          detailData.processTime
        }}</el-form-item>
        <el-form-item label="操作时间:" prop="time">{{
          detailData.time
        }}</el-form-item>
        <el-form-item label="处理人:" prop="processUser">{{
          detailData.processUser
        }}</el-form-item>
        <el-form-item label="备注:" prop="processRemark">{{
          detailData.processRemark
        }}</el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { request, downloadFile } from '@/utils/request'
import { common } from '@/common/mixin'
import { reactive, ref, toRefs } from '@vue/reactivity'
import { ElMessage } from 'element-plus'
import Table from '@/components/elp/table'
import { onMounted } from '@vue/runtime-core'
// import { formatTime } from "../../common/utils";
import { getLocation } from '@/utils/location.js'

export default {
  components: {
    Table,
  },
  mixins: [common],
  setup() {
    const goodsList = ref([])
    const getGoodsList = async (name='') => {
      try {
        const res = await request('getGoodsList', { name, page: 1, size: 20 })
        goodsList.value = (res.records || []).filter((item) => item.enable)
      } catch (error) {
        console.error(error)
      }
    }
    const state = reactive({
      tableRef: null,
      params: [
        {
          key: 'userName',
          value: '',
          label: '姓名',
        },
        {
          key: 'phoneNumber',
          value: '',
          label: '手机号',
        },
        {
          type: 'Select',
          key: 'refId',
          value: '',
          label: '兑换商品',
          options: {
            data: goodsList,
            label: 'name',
            value: 'id',
          },
          attrs: {
            filterable: true,
            remote: true,
            'remote-method': getGoodsList,
            onClear: () => {
              getGoodsList()
            },
          }
        },
        {
          type: 'Select',
          key: 'processStatus',
          value: '',
          label: '状态',
          options: {
            data: [
              { label: '未处理', value: 0 },
              { label: '已处理', value: 1 },
            ],
            label: 'label',
            value: 'value',
          },
        },
        {
          key: 'processRemark',
          value: '',
          label: '备注',
        },
        {
          type: 'Date',
          key: 'dateRangeCreate',
          value: [],
          label: '兑换时间',
          options: {
            type: 'datetimerange',
            format: 'YYYY-MM-DD HH:mm:ss',
            'value-format': 'YYYY-MM-DD HH:mm:ss',
            // "placeholder": "开始日期",
            'range-separator': '至',
            'start-placeholder': '开始日期',
            'end-placeholder': '结束日期',
            'default-time': [
              new Date(2000, 1, 1, 0, 0, 0),
              new Date(2000, 1, 1, 23, 59, 59),
            ],
          },
        },
        {
          type: 'Date',
          key: 'dateRangeProcess',
          value: [],
          label: '处理时间',
          options: {
            type: 'datetimerange',
            format: 'YYYY-MM-DD HH:mm:ss',
            'value-format': 'YYYY-MM-DD HH:mm:ss',
            // "placeholder": "开始日期",
            'range-separator': '至',
            'start-placeholder': '开始日期',
            'end-placeholder': '结束日期',
            'default-time': [
              new Date(2000, 1, 1, 0, 0, 0),
              new Date(2000, 1, 1, 23, 59, 59),
            ],
          },
        },
      ],
      dialogFormVisible: false,
      detailformRef: null,
      detailform: {
        id: '',
        name: '',
        result: undefined,
        expressOrderNo: '',
        processTime: '',
        processRemark: [],
      },
      dialogDetailVisible: false,
      detailData: {},
    })
    const detailformRules = reactive({
      result: {
        required: true,
        message: '请选择审核结果',
        trigger: 'blur',
      },
      expressOrderNo: {
        required: true,
        message: '请输入运单号',
        trigger: 'blur',
      },
      processTime: {
        required: true,
        message: '请选择处理时间',
        trigger: 'change',
      },
      processRemark: {
        message: '备注不能大于20个字符',
        trigger: 'blur',
        max: 20
      }
    })
    const getData = (params) => {
      const query = { ...params }
      if (params.dateRangeCreate?.length) {
        query.exchangeStartTime = params.dateRangeCreate[0]
        query.exchangeEndTime = params.dateRangeCreate[1]
      } else {
        query.exchangeStartTime = undefined
        query.exchangeEndTime = undefined
      }
      if (params.dateRangeProcess?.length) {
        query.processStartTime = params.dateRangeProcess[0]
        query.processEndTime = params.dateRangeProcess[1]
      } else {
        query.processStartTime = undefined
        query.processEndTime = undefined
      }
      return request('exchangeList', query)
    }
    const clearData = () => {
      getGoodsList()
    }
    const checkDetail = (row) => {
      state.dialogDetailVisible = true
      state.detailData = row
    }
    const handleDetail = (row) => {
      state.dialogFormVisible = true
      Object.keys(state.detailform).forEach((key) => {
        state.detailform[key] = null
      })
      state.detailform.id = row.recordId
      state.detailform.name = row.name
    }
    const onFormSubmit = async () => {
      try {
        // addTraining:新增 editTraining:编辑
        const url = state.detailform.result ? 'handleExchangeScore' : 'rejectExchangeScore'
        state.detailformRef && (await state.detailformRef.validate())
        await request(url, state.detailform)
        state.tableRef?.update()
        state.dialogFormVisible = false
        ElMessage({
          showClose: true,
          type: 'success',
          message: '提交成功!',
        })
      } catch (error) {
        console.error(error)
      }
    }
    const exportData = async (params = {}) => {
      try {
        const query = { ...params }
        query.page = undefined
        query.size = undefined
        if (params.dateRangeCreate?.length) {
          query.exchangeStartTime = params.dateRangeCreate[0]
          query.exchangeEndTime = params.dateRangeCreate[1]
        } else {
          query.exchangeStartTime = undefined
          query.exchangeEndTime = undefined
        }
        if (params.dateRangeProcess?.length) {
          query.processStartTime = params.dateRangeProcess[0]
          query.processEndTime = params.dateRangeProcess[1]
        } else {
          query.processStartTime = undefined
          query.processEndTime = undefined
        }
        const res = await downloadFile(
          'exchangeExport',
          {},
          {
            method: 'GET',
            params: query,
          }
        )
        // let blob = new Blob([res],{type: "application/octet-stream"})
        let reader = new FileReader()
        reader.readAsDataURL(res)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '积分兑换.xlsx'
          a.href = e.target.result
          a.click()
        }
      } catch (error) {
        console.error(error)
      }
    }
    onMounted(() => {
      getGoodsList()
    })
    return {
      ...toRefs(state),
      detailformRules,
      getData,
      clearData,
      checkDetail,
      handleDetail,
      onFormSubmit,
      exportData,
      getLocation
    }
  },
}
</script>
<style scoped>
.section-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.list-item {
  margin-bottom: 0;
  text-align: left;
}
.one-line-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
