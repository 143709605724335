<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData" add @add="addNew">
    </Filter>
    <Table ref="tableId" :tableData="tableDataList" :total="total" :size="size" @update="updatePage" @sort-change="handleSortChange" tooltip-effect="light">
      <el-table-column type="index" align="center" label="序号" width="60px" />
      <el-table-column prop="resourceType" label="资源类别"></el-table-column>
      <el-table-column prop="fileType" label="文件类型"></el-table-column>
      <el-table-column prop="title" label="文档名称"></el-table-column>
      <el-table-column prop="language" label="语言版本"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column prop="updateTime" label="修订时间" /> 
      <el-table-column prop="enCode" label="二维码" width="120">
        <template #default="{ row }">
          <el-image
            v-if="row.language == '英文'"
            style="width: 100px; height: 100px"
            :src="row.enCode"
            :preview-src-list="[row.enCode]"
          >
          </el-image>
          <el-image
            v-if="row.language == '中文'"
            style="width: 100px; height: 100px"
            :src="'data:image/jpeg;base64,' + row.cnCode"
            :preview-src-list="['data:image/jpeg;base64,' + row.cnCode]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="scanTime" label="预览" width="80" sortable="custom" >
        <template #default="{ row }">{{ row.scanTime }}</template>
      </el-table-column>
      <el-table-column prop="downloadTime" label="下载" width="80" sortable="custom" >
        <template #default="{ row }">{{ row.downloadTime }}</template>
      </el-table-column>
      <el-table-column prop="description" label="资料描述" show-overflow-tooltip width="240" />
      <el-table-column prop="comment" label="备注" /> 
      <el-table-column label="操作"  width="210" fixed="right">
        <template #default="{ row }">
          <el-button type="primary" plain size="mini" @click="editItem(row)">编辑</el-button>
          <el-button type="danger" plain size="mini" @click="deleteItem(row)">删除</el-button>
        </template>
      </el-table-column>
    </Table>
    <el-dialog title="编辑" top="5vh" v-model="dialogFormVisible" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="detailformRef" class="dialog-form" :model="detailform" :rules="detailformRules" label-width="100px">
        <el-form-item label="文档名称" prop="title">
          <el-input v-model="detailform.title" placeholder="文档名称" clearable />
        </el-form-item>
        <el-form-item label="语言版本" prop="language">
          <el-select v-model="detailform.language" placeholder="语言版本" clearable>
            <el-option
              v-for="item in langList"
              :key="item"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="资源类别" prop="resourceType">
          <el-select v-model="detailform.resourceType" placeholder="资源类别" clearable>
            <el-option
              v-for="item in resourceList"
              :key="item"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文件类型" prop="fileType">
          <el-select v-model="detailform.fileType" placeholder="文件类型" clearable @change="onFileTypeChange">
            <el-option
              v-for="item in fileTypes"
              :key="item"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传文件：" prop="file" v-if="detailform.fileType">
          <Upload
            :file-list="fileList"
            :limit="1"
            :auto-upload="false"
            :on-change="onFileChange"
            :on-remove="onFileRemove"
          >
            <el-button :disabled="!!detailform.file" type="text">点击上传文件</el-button>
            <template #tip>
              <div v-if="detailform.fileType == '文档'" class="el-upload__tip">数量1，格式为pdf</div>
              <div v-else-if="detailform.fileType == '视频'" class="el-upload__tip">数量1，格式为mp4</div>
              <div v-else-if="detailform.fileType == '压缩包'" class="el-upload__tip">数量1，格式为zip、rar、tar、gz</div>
            </template>
          </Upload>
        </el-form-item>
        <el-form-item label="资料描述" prop="description">
          <el-input type="textarea" v-model="detailform.description" :autosize="{ minRows: 4 }" placeholder="资料描述"  clearable />
        </el-form-item>
        <el-form-item label="备注" prop="comment">
          <el-input type="textarea" v-model="detailform.comment" :autosize="{ minRows: 4 }" placeholder="备注"  clearable />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" size="small" @click="onFormSubmit">确认</el-button>
      </template>
    </el-dialog>
    <el-image-viewer
      v-if="viewerList?.length"
      :url-list="viewerList"
      :initial-index="viewerIndex"
      :infinite="false"
      :z-index="3000"
      @close="viewerList=[]"
    ></el-image-viewer>
  </div>
</template>

<script>
import { ref, reactive, toRefs } from "vue";
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from "element-plus";

import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import Upload from '@/components/upload'

import { common } from "@/common/mixin";
import { arrayToMap } from "@/common/constant";
import { request, service, urlMap } from "@/utils/request";
import { reqFile, reqFileData, reqFileDelete } from '@/api/resource'

export default {
  components: {
    Table,
    Filter,
    Upload
  },
  mixins: [common],
  setup() {
    const router = useRouter()
    const resourceList = [
      { label: '教育资源', value: '教育资源' },
      { label: '其它资源', value: '其它资源' },
    ]
    const fileTypes = [
      { label: '文档', value: '文档' }, // pdf
      { label: '视频', value: '视频' }, // mp4
      { label: '压缩包', value: '压缩包' }, // zip rar tar gz
    ]
    const langList = [
      { label: '中文', value: '中文' },
      { label: '英文', value: '英文' },
    ]

    const state = reactive({
      params: [
        {
          type: "Select",
          key: "resourceType",
          value: "",
          label: "资源类别",
          options: {
            data: resourceList,
            label: "label",
            value: "value"
          }
        },
        {
          type: "Select",
          key: "fileType",
          value: "",
          label: "文件类型",
          options: {
            data: fileTypes,
            label: "label",
            value: "value"
          }
        },
        {
          key: "title",
          value: "",
          label: "文档名称",
        },
        {
          type: "Select",
          key: "language",
          value: "",
          label: "语言版本",
          options: {
            data: langList,
            label: "label",
            value: "value"
          }
        },
        {
          type: "Date",
          key: "createTime",
          value: [],
          label: "创建时间",
          options: {
            "type": "datetimerange",
            "format": "YYYY-MM-DD HH:mm:ss",
            "value-format": "YYYY-MM-DD HH:mm:ss",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期",
            "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
          }
        }
      ],
      query: {},
      sortData: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      dialogFormVisible: false,
      detailformRef: null,
      fileList: [],
      detailform: {
        id: '',
        title: '',
        language: '',
        resourceType: '',
        fileType: '',
        fileName: '',
        file: '',
        description: '',
        comment: ''
      },
      viewerList: [],
      viewerIndex: 0
    })
    const detailformRules = reactive({
      title: {
        required: true,
        message: '请输入文件名称',
        trigger: 'blur'
      },
      language: {
        required: true,
        message: '请选择语言版本',
        trigger: 'change'
      },
      resourceType: {
        required: true,
        message: '请选择资源类型',
        trigger: 'change'
      },
      fileType: {
        required: true,
        message: '请选择文件类型',
        trigger: 'change'
      },
      file: {
        required: true,
        message: '请上传文件',
        trigger: 'change'
      },
    })
    const getData = async () => {
      try {
        const res = await request("getResourceList", {
          ...state.query,
          ...state.sortData,
          page: state.page,
          size: state.size,
        })
        state.tableDataList = res.records
        state.total = res.total
      } catch (error) {
        console.error(error)
      }
    }
    const searchData = async (data) => {
      console.log('searchData', data)
      state.query = data;
      getData()
    }
    const updatePage = (page, size) => {
      state.page = page || 1
      state.size = size || 10
      getData()
    }
    const handleSortChange = ({column, prop, order}) => {
      // ascending descending null
      console.log('onSortChange', column, prop, order)
      // context.emit('sort-change', {column, prop, order})
      const orderMap = { ascending: 1, descending: 2 }
      state.sortData = {
        orderField: prop,
        orderType: orderMap[order]
      }
      updatePage()
    }
    const onFileChange = (file) => {
      console.log('onFileChange -->', file)
      if (state.detailform.fileType == '文档') {
        let res = false
        for (let item of ['pdf']) {
          if (file.name.includes(item)) {
            res = true
            break
          }
        }
        if (!res) {
          state.fileList = []
          ElMessage({
            showClose: true,
            type: "warning",
            message: "只能上传pdf格式的文件"
          })
          return
        }
        state.detailform.file = file.raw
        state.detailform.fileName = file.name
      } else if (state.detailform.fileType == '视频') {
        let res = false
        for (let item of ['mp4']) {
          if (file.name.includes(item)) {
            res = true
            break
          }
        }
        if (!res) {
          state.fileList = []
          ElMessage({
            showClose: true,
            type: "warning",
            message: "只能上传mp4格式的文件"
          })
          return
        }
        state.detailform.file = file.raw
        state.detailform.fileName = file.name
      } else if (state.detailform.fileType == '压缩包') {
        let res = false
        for (let item of ['zip', 'rar', 'tar', 'gz']) {
          if (file.name.includes(item)) {
            res = true
            break
          }
        }
        if (!res) {
          state.fileList = []
          ElMessage({
            showClose: true,
            type: "warning",
            message: "只能上传zip、rar、tar、gz格式的文件"
          })
          return
        }
        state.detailform.file = file.raw
        state.detailform.fileName = file.name
      } else {
        ElMessage({
          showClose: true,
          type: "warning",
          message: "请先选择文件类型"
        })
        state.fileList = []
      }
    }
    const onFileRemove = () => {
      state.detailform.file = undefined
    }
    const onFileTypeChange = () => {
      state.detailform.file = ''
      state.fileList = []
    }
    const addNew = () => {
      state.dialogFormVisible = true
      state.detailform.deleteFile = false
      Object.keys(state.detailform).forEach(key => {
        state.detailform[key] = undefined
      })
      state.detailform.file = ''
      state.fileList = []
    }
    const editItem = async (row) => {
      try {
        const res = await reqFileData({ id: row.id })
        Object.keys(state.detailform).forEach(key => {
          state.detailform[key] = row[key]
        })
        state.dialogFormVisible = true
        state.detailform.deleteFile = false
        if (res.fileUrl) {
          state.detailform.file = res.fileUrl
          state.fileList = [{ name: res.fileName, url: res.fileUrl }]
          // const resBlob = await reqFile(res.fileUrl)
          // state.detailform.file = new File([resBlob.data], res.fileName)
        } else {
          state.detailform.file = ''
          state.fileList = []
        }
        console.log('editItem -->', state.detailform.file)
      } catch (error) {
        console.error(error)
      }
    }
    const deleteItem = async (row) => {
      try {
        await ElMessageBox.confirm(
          '确定删除？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        await reqFileDelete({ id: row.id })
        ElMessage({
          showClose: true,
          type: "success",
          message: "删除成功!"
        })
        getData()
      } catch (error) {
        console.error(error)
      }
    }
    const onFormSubmit = async () => {
      try {
        console.log('onFormSubmit', state.detailform)
        state.detailformRef && await state.detailformRef.validate()

        const data = new FormData()
        Object.keys(state.detailform).forEach((key) => {
          state.detailform[key] && data.append(key, state.detailform[key])
        })
        if (state.detailform.file instanceof File) {
          state.detailform.deleteFile = true
        } else {
          data.delete('file')
        }
        await request('editResource', data)
        getData()
        state.dialogFormVisible = false
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }
    const setupInit = () => {
      try {
        getData()
        // request("syncActivity")
      } catch (error) {
         console.error(error)
      }
    }
    setupInit()
    return {
      resourceList,
      fileTypes,
      langList,
      ...toRefs(state),
      detailformRules,
      getData,
      searchData,
      addNew,
      editItem,
      deleteItem,
      onFormSubmit,
      updatePage,
      onFileTypeChange,
      onFileChange,
      onFileRemove,
      handleSortChange
    }
  },
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.el-upload__tip {
  color: red;
}
</style>
