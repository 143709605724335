<template>
  <div class="section-container">
    <Table ref="tableRef" :params="params" :request="getData" tooltip-effect="light">
      <template #search="{query}">
        <el-form-item class="list-item" label="积分:">
          <div>{{ score }}</div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="margin-left: 20px;" @click="changeScore()">积分调整</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="exportData(query)">表格导出</el-button>
        </el-form-item>
      </template>
      <el-table-column type="index" label="序号" width="60px" />
      <el-table-column prop="createTime" label="时间" />
      <el-table-column prop="userName" label="姓名" />
      <el-table-column prop="userLabel" label="用户标签" >
        <template #default="{row}">
          {{ row.userLabel == 1 ? '内部用户' : '普通用户' }}
        </template>
      </el-table-column>
      <el-table-column prop="phoneNumber" label="手机" />
      <el-table-column prop="name" label="积分项" >
        <template #default="{row}">
          <template v-if="row.addUserId">{{ scoreReason[row.eventType][row.addReason] }}</template>
          <template v-else>{{ row.eventUserName }}{{ row.name }}</template>
        </template>
      </el-table-column>
      <el-table-column prop="eventType" label="积分类型" >
        <template #default="{row}">{{ eventTypeMap[row.eventType] }}</template>
      </el-table-column>
      <el-table-column prop="score" label="积分变更" />
      <el-table-column prop="addUserName" label="添加人" />
      <el-table-column prop="addEventRemark" label="备注" />
    </Table>
    <el-dialog title="积分调整" top="5vh" v-model="dialogFormVisible" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="detailformRef" class="dialog-form" :model="detailform" :rules="detailformRules" label-width="100px">
        <el-form-item label="手机号" prop="userId">
          <el-select v-model="detailform.userId" filterable placeholder="手机号" clearable remote :remote-method="getUserListByPhone">
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="`${item.phoneNumber} ${item.userName}`"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="积分项" prop="project">
          <el-select v-model="detailform.project" placeholder="积分项" clearable>
            <el-option
              v-for="item in [{id: 1, name: '积分调增'}, {id: 2, name: '积分调减'}]"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="调整原因" prop="reason">
          <el-select v-model="detailform.reason" placeholder="调整原因" clearable>
            <el-option
              v-for="(item, index) in scoreReason[detailform.project]"
              :key="index"
              :label="item"
              :value="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分值" prop="score">
          <el-input v-if="detailform.project == 1" v-model="detailform.score" placeholder="分值" clearable />
          <el-select v-else v-model="detailform.goodsId" placeholder="分值" clearable>
            <el-option
              v-for="item in goodsList"
              :key="item.goodId"
              :label="item.goodsName"
              :value="item.goodId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="eventRemark">
          <el-input v-model="detailform.eventRemark" placeholder="备注" clearable />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" size="small" @click="onFormSubmit">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { request, downloadFile } from "@/utils/request";
import { common } from "@/common/mixin";
import { onMounted, ref, reactive, toRefs, watch } from "vue";
import { useRoute } from 'vue-router'
import { ElMessage } from "element-plus";
import Table from "@/components/elp/table";
// import { formatTime } from "../../common/utils";


export default {
  components: {
    Table,
  },
  mixins: [common],
  setup() {
    const route = useRoute()
    // const userId = route.query.id || ''
    const userName = route.query.name || ''
    const phoneNumber = route.query.phoneNumber || ''

    const scoreReason = {
      1: ['优秀帖子', '优秀案例', '其它'],
      2: ['恶意刷分', '内容重复', '其它'],
      3: ['优秀帖子', '优秀案例', '其它'],
      4: ['恶意刷分', '内容重复', '其它']
    }
    const scoreNameList = ref([])
    const eventTypeList = [
      { label: '推荐新用户注册', value: 1 },
      { label: '推荐人激励', value: 2 },
      { label: '积分调增', value: 3 },
      { label: '积分调减', value: 4 },
      { label: '管理员激励', value: 5 },
      { label: '个人任务', value: 6 },
      { label: '默认', value: 100 },
    ]
    const eventTypeMap = {
      1: '推荐新用户注册',
      2: '推荐人激励',
      3: '积分调增',
      4: '积分调减',
      5: '管理员激励',
      6: '个人任务',
      100: '默认',
    }
    const userList = ref([])
    const getUserListByName = async (userName) => {
      try {
        const res = await request("getUserList", { userName });
        userList.value = res.records;
      } catch (error) {
        console.error(error);
      }
    }
    const getUserListByPhone = async (phoneNumber) => {
      try {
        const res = await request("getUserList", { phoneNumber, page: 1, size: 20 });
        userList.value = res.records;
      } catch (error) {
        console.error(error);
      }
    }

    const state = reactive({
      userName: userName,
      score: '',
      tableRef: null,
      params: [
        {
          key: "userName",
          value: userName,
          label: "姓名",
        },
        {
          key: "phoneNumber",
          value: phoneNumber,
          label: "手机",
        },
        {
          type: "Select",
          key: "refId",
          value: '',
          label: "积分项",
          options: {
            data: scoreNameList,
            label: "name",
            value: "id"
          }
        },
        {
          type: "Select",
          key: "eventType",
          value: "",
          label: "积分类型",
          options: {
            data: eventTypeList,
            label: "label",
            value: "value"
          }
        },
        {
          type: "Date",
          key: "dateRangeCreate",
          value: [],
          label: "时间",
          options: {
            "type": "datetimerange",
            "format": "YYYY-MM-DD HH:mm:ss",
            "value-format": "YYYY-MM-DD HH:mm:ss",
            // "placeholder": "开始日期",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期",
            "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
          }
        },
      ],
      dialogFormVisible: false,
      detailformRef: null,
      detailform: {
        userId: '',
        goodsId: '', // 减法商品项目
        project: '', // 积分项
        reason: '', // 调整原因
        score: '', // 分值
        eventRemark: '' // 备注
      },
      goodsList: [],
    })
    const detailformRules = reactive({
      userId: {
        required: true,
        message: '请输入手机号',
        trigger: 'change',
      },
      project: {
        required: true,
        message: '请选择积分项',
        trigger: 'change',
      },
      reason: {
        required: true,
        message: '请输入调整原因',
        trigger: 'blur'
      },
      score: {
        required: true,
        message: '请输入调整分值',
        trigger: 'blur'
      },
    })

    watch(() => state.detailform.goodsId, (value) => {
      for (let good of state.goodsList) {
        if (good.goodId == value) {
          state.detailform.score = good.score
          return
        }
      }
    })
    const getScoreStatistics = async (query) => {
      try {
        const res = await request("getScoreStatistics", {
          ...query,
          type: 0
        });
        state.score = res
      } catch (error) {
        console.error(error);
      }
    }
    const getData = async (params={}) => {
      const query = { ...params }
      // 时间
      if (params.dateRangeCreate?.length) {
        query.startTime = params.dateRangeCreate[0]
        query.endTime = params.dateRangeCreate[1]
      } else {
        query.startTime = undefined
        query.endTime = undefined
      }
      getScoreStatistics(query)
      return request("getUserScoreDetail", {
        ...query,
        type: 0
      })
    };
    const getScoreNameList = async () => {
      try {
        scoreNameList.value = await request("getScoreNameList");
      } catch (error) {
        console.error(error);
      }
    }
    
    const getGoodsAdmin = async () => {
      try {
        state.goodsList = await request("getGoodsAdmin");
      } catch (error) {
        console.error(error);
      }
    }
    const changeScore = () => {
      state.dialogFormVisible = true
      Object.keys(state.detailform).forEach((key) => {
        state.detailform[key] = null
      })
    }
    const onFormSubmit = async () => {
      try {
        await request('changetUserScore', state.detailform)
        // getUserScore()
        state.tableRef?.update()

        state.dialogFormVisible = false
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }
    const exportData = async (params={}) => {
      try {
        const query = { ...params }
        query.page = undefined
        query.size = undefined
        // 时间
        if (params.dateRangeCreate?.length) {
          query.startTime = params.dateRangeCreate[0]
          query.endTime = params.dateRangeCreate[1]
        } else {
          query.startTime = undefined
          query.endTime = undefined
        }
        const res = await downloadFile("exportScoreDetails", {}, {
          method: 'GET',
          params: query
        });
        // let blob = new Blob([res],{type: "application/octet-stream"})
        let reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '积分详情.xlsx'
          a.href = e.target.result
          a.click()
        }
      } catch (error) {
        console.error(error)
      }
    }
    onMounted(() => {
      getScoreNameList()
      getGoodsAdmin()
    })
    return {
      scoreReason,
      eventTypeMap,
      userList,
      ...toRefs(state),
      detailformRules,
      getData,
      getUserListByName,
      getUserListByPhone,
      exportData,
      changeScore,
      onFormSubmit
    };
  },
};
</script>
<style scoped>
.section-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.list-item {
  margin-bottom: 0;
  text-align: left;
}
</style>