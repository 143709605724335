<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData" add @add="addNew">
    </Filter>
    <Table ref="tableId" :tableData="tableDataList" :total="total" :size="size" @update="updatePage" tooltip-effect="light" single @select="selectHandle">
      <el-table-column type="index" align="center" label="序号" width="60px" />
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column prop="name" label="页面名称" />
      <el-table-column prop="imageUrl" label="标题图">
        <template #default="{row}">
          <el-image
            v-if="row.imageUrl"
            style="width: 100px; height: 100px"
            :src="row.imageUrl"
            :preview-src-list="[row.imageUrl]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="createBy" label="创建人" />
      <el-table-column prop="updateTime" label="最近更新时间" /> 
      <el-table-column label="操作"  width="210" fixed="right">
        <template #default="{row}">
          <el-button type="primary" plain size="mini" @click="editItem(row)">编辑</el-button>
        </template>
      </el-table-column>
    </Table>
    <el-dialog title="内容编辑" top="5vh" v-model="dialogFormVisible" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="detailformRef" class="dialog-form" :model="detailform" :rules="detailformRules" label-width="100px">
        <el-form-item label="页面名称" prop="name">
          <el-input v-model="detailform.name" placeholder="banner名称" clearable />
        </el-form-item>
        <el-form-item label="标题图" prop="image">
          <el-upload
            :file-list="imageList"
            action="#"
            :limit="1"
            :auto-upload="false"
            name="image"
            list-type="picture-card"
            :on-change="onImageChange"
            :on-remove="onImageRemove"
            :on-preview="onImagePreview"
          >
            <i class="el-icon-plus"></i>
            <template #tip>
              <div class="el-upload__tip">
                数量1张，大小不超过 500KB，
                <span style="color:red;">图片尺寸：660*360</span>
              </div>
            </template>
          </el-upload>
        </el-form-item>
        <el-form-item label="活动内容：" prop="content">
          <div class="el-editor" style="">
            <Editor ref="editorRef" v-model="detailform.content" />
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" size="small" @click="onFormSubmit">确认</el-button>
      </template>
    </el-dialog>
    <el-image-viewer
      v-if="viewerList?.length"
      :url-list="viewerList"
      :initial-index="viewerIndex"
      :infinite="false"
      :z-index="3000"
      @close="viewerList=[]"
    ></el-image-viewer>
  </div>
</template>

<script>
import { ref, reactive, toRefs } from "vue";
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from "element-plus";

import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import Editor from '@/components/editor'

import { common } from "@/common/mixin";
import { request } from "@/utils/request";

export default {
  components: {
    Table,
    Filter,
    Editor
  },
  mixins: [common],
  setup() {
    const router = useRouter()
    const pageList = ref([])

    const state = reactive({
      params: [
        {
          key: "name",
          value: "",
          label: "页面名称",
        },
        {
          key: "createBy",
          value: "",
          label: "创建人",
        },
      ],
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      batchDataList: [],
      dialogFormVisible: false,
      detailformRef: null,
      imageList: [],
      detailform: {
        id: '',
        name: '',
        image: [],
        content: '',
      },
      viewerList: [],
      viewerIndex: 0
    })
    const detailformRules = reactive({
      name: {
        required: true,
        message: '请输入页面名称',
        trigger: 'blur'
      },
      image: {
        required: true,
        message: '请选择标题图',
        trigger: 'change'
      },
      content: {
        required: true,
        message: '请输入页面内容',
        trigger: 'change'
      },
    })

    const getData = async () => {
      try {
        const res = await request("getContentPage", {
          ...state.query,
          pageNum: state.page,
          pageSize: state.size,
        })
        state.tableDataList = res.records
        state.total = res.total
      } catch (error) {
        console.error(error)
      }
    }
    const searchData = async (data) => {
      console.log('searchData', data)
      state.query = data;
      getData()
    }
    const updatePage = (page, size) => {
      state.page = page || 1
      state.size = size || 10
      getData()
    }
    const onChannelChange = (value) => {
      state.detailform.url = ''
    }
    const onPageChange = (value) => {
      state.detailform.associatePage = ''
      state.detailform.url = ''
      if (value) {
        for(let page of pageList.value) {
          if(page.id == value) {
            state.detailform.associatePage = page.name
            return
          } 
        }
      }
    }

    const onImageChange = (file) => {
      console.log('onImageChange -->', file)
      state.detailform.image = file.raw
    }
    const onImageRemove = () => {
      state.detailform.image = undefined
    }
    const onImagePreview = (item) => {
      console.log('onImagePreview', item)
      if (item) {
        state.viewerList = state.imageList.map(item => item.url)
        state.viewerIndex = state.viewerList.indexOf(item.url)
      }
    }
    // 表格select事件
    const selectHandle = (data) => {
      console.log(data)
      state.batchDataList = data.map((item)=>{
        return item.id
      })
    }
    const addNew = () => {
      state.dialogFormVisible = true
      Object.keys(state.detailform).forEach(key => {
        state.detailform[key] = undefined
      })
      state.imageList = []
    }
    const editItem = (row) => {
      state.dialogFormVisible = true
      Object.keys(state.detailform).forEach(key => {
        state.detailform[key] = row[key]
      })
      state.detailform.image = row.imageUrl
      state.imageList = row.imageUrl ? [{ name: row.imagePath, url: row.imageUrl }] : []
    }
    const onFormSubmit = async () => {
      try {
        console.log('onFormSubmit', state.detailform)
        state.detailformRef && await state.detailformRef.validate()
        const data = new FormData()
        Object.keys(state.detailform).forEach((key) => {
          state.detailform[key] && data.append(key, state.detailform[key])
        })
        if (!(state.detailform.image instanceof File)) {
          data.delete('image')
        }
        await request('editContent', data)
        getData()
        state.dialogFormVisible = false
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }
    const batchDelete = async () => {
      try {
        if (state.batchDataList.length<1) {
          ElMessage({
            showClose: true,
            type: "error",
            message: "请选择要删除的活动!"
          })
          return
        }
        await ElMessageBox.confirm(
          '确定删除？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        await request("deleteActivity", {
          id: state.batchDataList[0]
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "操作成功!"
        })
        getData()
      } catch (error) {
        console.error(error)
      }
    }
    // 中止活动
    const batchStop = async () => {
      try {
        if (state.batchDataList.length<1) {
          ElMessage({
            showClose: true,
            type: "error",
            message: "请选择要中止的活动!"
          })
          return
        }
        await request("stopActivity", {
          id: state.batchDataList[0]
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "操作成功!"
        })
        getData()
      } catch (error) {
        console.error(error)
      }
    }
    // 发布活动
    const batchPublish = async () => {
      try {
        if (state.batchDataList.length<1) {
          ElMessage({
            showClose: true,
            type: "error",
            message: "请选择要发布的活动!"
          })
          return
        }
        await request("publishActivity", {
          id: state.batchDataList[0]
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "操作成功!"
        })
        getData()
      } catch (error) {
        console.error(error)
      }
    }
    const disabledDate = (time) => {
      return time.getTime() > Date.now()
    }
    const setupInit = () => {
      try {
        getData()
        // request("syncActivity")
      } catch (error) {
         console.error(error)
      }
    }
    setupInit()
    return {
      pageList,
      ...toRefs(state),
      detailformRules,
      getData,
      searchData,
      addNew,
      editItem,
      onFormSubmit,
      updatePage,
      disabledDate,
      onChannelChange,
      onPageChange,
      onImageChange,
      onImageRemove,
      onImagePreview,
      batchStop,
      batchPublish,
      batchDelete,
      selectHandle,
    }
  },
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>
