<template>
  <el-upload
    ref="uploadRef"
    action="#"
    list-type="picture-card"
    :auto-upload="false"
    :limit="1"
    :show-file-list="false"
    :on-change="onImageChange"
    :on-remove="onImageRemove"
  >
    <div v-if="imageUrl" class="avatar" @click="e => e.stopPropagation()">
      <img :src="imageUrl" style="width: 100%; height: 100%;" />
      <span class="avatar-actions">
        <span class="avatar-preview" @click="previewImage"><i class="el-icon-zoom-in"></i></span>
        <span class="avatar-delete" @click="deleteImage"><i class="el-icon-delete"></i></span>
      </span>
    </div>
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    <template #tip>
      <div class="el-upload__tip">
        数量1张，大小不超过 500KB，
        <span style="color:red;">图片尺寸：750*300</span>
      </div>
    </template>
  </el-upload>
  <el-image-viewer v-if="viewerList?.length" :url-list="viewerList" :infinite="false" @close="viewerList = []"></el-image-viewer>
</template>

<script>
import { ref, reactive, toRefs, onMounted, computed } from "vue";

export default {
  components: {
  },
  props: {
    image: {
      type: String,
      default: ''
    },
    modelValue: {
      required: true
    },
  },
  setup(props, context) {
    console.log('avatar -->', props)
    const uploadRef = ref(null)
    const state = reactive({
      imageUrl: props.modelValue,
      viewerList: []
    })
    const onImageChange = (file) => {
      console.log('onImageChange -->', file)
      state.imageUrl = URL.createObjectURL(file.raw)
      context.emit('update:modelValue', file.raw)
    }
    const onImageRemove = () => {
      state.detailform.image = undefined
      context.emit('update:modelValue', undefined)
    }
    const previewImage = () => {
      console.log('previewImage')
      state.viewerList = [state.imageUrl]
    }
    const deleteImage = (e) => {
      console.log('deleteImage')
      uploadRef.value?.clearFiles()
      state.imageUrl = ''
      context.emit('update:modelValue', undefined)
    }
    onMounted(() => {
      context.emit('update:modelValue', undefined)
    })

    return {
      uploadRef,
      ...toRefs(state),
      onImageChange,
      onImageRemove,
      previewImage,
      deleteImage,
    }
  }
};
</script>

<style scoped>
.avatar {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: default;
}
.avatar-actions {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0,0,0,.5);
  transition: opacity var(--el-transition-duration);
}
.avatar-actions:hover {
  opacity: 1
}
.avatar-actions i {
  cursor: pointer;
  margin: 5px;
  color: #fff;
}
.avatar-uploader-icon {

}
</style>
