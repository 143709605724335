<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData" add @add="addNew">
    </Filter>
    <Table ref="tableId" :tableData="tableDataList" :total="total" :size="size" @update="updatePage" tooltip-effect="light">
      <el-table-column type="index" align="center" label="序号" width="60px" />
      <el-table-column prop="name" label="banner名称" />
      <el-table-column prop="associatePage" label="关联产品页" width="120">
        <template #default="{row}">
          {{ row.associatePage }}
        </template>
      </el-table-column>
      <el-table-column prop="position" label="banner位置" width="120">
        <template #default="{row}">{{ row.position }}</template>
      </el-table-column>
      <el-table-column prop="image" label="图片">
        <template #default="{row}">
          <el-image
            v-if="row.imageUrl"
            style="width: 100px; height: 100px"
            :src="row.imageUrl"
            :preview-src-list="[row.imageUrl]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="offShell" label="上架状态" width="120">
        <template #default="{row}">{{ row.offShell ? '下架' : '上架' }}</template>
      </el-table-column>
      <el-table-column prop="startTime" label="开始时间" />
      <el-table-column prop="endTime" label="结束时间" /> 
      <el-table-column label="操作"  width="210" fixed="right">
        <template #default="{row}">
          <el-button v-if="row.offShell" type="primary" plain size="mini" @click="editItem(row)">编辑</el-button>
          <el-button v-if="row.offShell" type="primary" plain size="mini" @click="editStatus(row, false)">上架</el-button>
          <el-button v-else type="warning" plain size="mini" @click="editStatus(row, true)">下架</el-button>
        </template>
      </el-table-column>
    </Table>
    <el-dialog title="banner编辑" top="5vh" v-model="dialogFormVisible" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="detailformRef" class="dialog-form" :model="detailform" :rules="detailformRules" label-width="100px">
        <el-form-item label="banner位置" prop="position">
          <el-select v-model="detailform.position" placeholder="banner位置" clearable>
            <el-option
              v-for="item in [1, 2, 3, 4, 5]"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="banner名称" prop="name">
          <el-input v-model="detailform.name" placeholder="banner名称" clearable />
        </el-form-item>
        <el-form-item label="来源渠道" prop="channel">
          <el-select v-model="detailform.channel" placeholder="来源渠道" clearable @change="onChannelChange">
            <el-option
              v-for="item in channelList"
              :key="item"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="detailform.channel != 4" label="关联页面" prop="associatePageId">
          <el-select
            v-model="detailform.associatePageId"
            :remote-method="getPageList"
            clearable
            filterable
            placeholder="关联页面"
            remote
            @change="onPageChange"
            @clear="getPageList"
          >
            <el-option
              v-for="item in pageList"
              :key="item"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="detailform.channel == 4" label="链接" prop="url">
          <el-input v-model="detailform.url" placeholder="链接" clearable />
        </el-form-item>
        <el-form-item label="banner图片" prop="image">
          <!-- <Avatar v-model="detailform.image"></Avatar> -->
          <el-upload
            :file-list="imageList"
            action="#"
            :limit="1"
            :auto-upload="false"
            name="image"
            list-type="picture-card"
            :on-change="onImageChange"
            :on-remove="onImageRemove"
            :on-preview="onImagePreview"
          >
            <i class="el-icon-plus"></i>
            <template #tip>
              <div class="el-upload__tip">
                数量1张，大小不超过 500KB，
                <span style="color:red;">图片尺寸：750*300</span>
              </div>
            </template>
          </el-upload>
        </el-form-item>
        <el-form-item label="时间范围: " prop="dateRange">
          <el-date-picker
            v-model="detailform.dateRange"
            type="datetimerange"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :default-time="[new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]"
            clearable
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" size="small" @click="onFormSubmit">确认</el-button>
      </template>
    </el-dialog>
    <el-image-viewer
      v-if="viewerList?.length"
      :url-list="viewerList"
      :initial-index="viewerIndex"
      :infinite="false"
      :z-index="3000"
      @close="viewerList=[]"
    ></el-image-viewer>
  </div>
</template>

<script>
import { ref, reactive, toRefs } from "vue";
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from "element-plus";

import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import Avatar from "@/components/upload/avatar";

import { common } from "@/common/mixin";
import { request, service, urlMap } from "@/utils/request";

export default {
  components: {
    Table,
    Filter,
    Avatar
  },
  mixins: [common],
  setup() {
    const router = useRouter()
    const channelList = [
      { label: '内容列表', value: '1' },
      { label: '市场活动', value: '2' },
      { label: '技术论坛', value: '3' },
      { label: '自定义', value: '4' },
    ]
    const channelMap = {
      1: { label: '内容列表', page: '/packages/service/pages/banner/content?id=' },
      2: { label: '市场活动', page: '/pages/activityDetail/activityDetail?id=' },
      3: { label: '技术论坛', page: '/pages/bbsDetail/bbsDetail?id=' },
    }
    const pageList = ref([])

    const state = reactive({
      params: [
        {
          key: "name",
          value: "",
          label: "banner名称",
        },
        {
          type: "Select",
          key: "offShell",
          value: "",
          label: "上架状态",
          options: {
            data: [{ label: '上架', value: false }, { label: '下架', value: true }],
            label: "label",
            value: "value"
          }
        },
      ],
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      batchDataList: [],
      dialogFormVisible: false,
      detailformRef: null,
      imageList: [],
      detailform: {
        id: '',
        position: '',
        name: '',
        channel: '',
        associatePage: '',
        associatePageId: '',
        url: '',
        image: [],
        startTime: '',
        endTime: '',
        dateRange: [],
      },
      viewerList: [],
      viewerIndex: 0
    })
    const detailformRules = reactive({
      position: {
        required: true,
        message: '请选择banner位置',
        trigger: 'change'
      },
      name: {
        required: true,
        message: '请输入banner名称',
        trigger: 'blur'
      },
      channel: {
        required: true,
        message: '请选择来源渠道',
        trigger: 'change'
      },
      associatePage: {
        required: true,
        message: '请选择关联页面',
        trigger: 'change'
      },
      url: {
        required: false,
        message: '请输入跳转链接',
        trigger: 'blur'
      },
      dateRange: {
        required: true,
        message: '请选择时间范围',
        trigger: 'change',
      },
    })

    const getData = async () => {
      try {
        const res = await request("getBannerPage", {
          ...state.query,
          pageNum: state.page,
          pageSize: state.size,
        })
        state.tableDataList = res.records
        state.total = res.total
      } catch (error) {
        console.error(error)
      }
    }
    const searchData = async (data) => {
      console.log('searchData', data)
      state.query = data;
      getData()
    }
    const updatePage = (page, size) => {
      state.page = page || 1
      state.size = size || 10
      getData()
    }
    const onChannelChange = (value) => {
      state.detailform.associatePageId = ''
      state.detailform.associatePage = ''
      state.detailform.url = ''
      getPageList()
    }
    const onPageChange = (value) => {
      state.detailform.associatePage = ''
      state.detailform.url = ''
      if (value) {
        for(let page of pageList.value) {
          if(page.id == value) {
            state.detailform.associatePage = page.name
            state.detailform.url = channelMap[state.detailform.channel]?.page + value
            return
          }
        }
      }
    }
    const getPageList = (name) => {
      pageList.value = []
      if (state.detailform.channel == 1) {
        getContentList(name)
      } else if (state.detailform.channel == 2) {
        getActivityList(name)
      } else if (state.detailform.channel == 3) {
        getForumList(name)
      }
    }
    const getContentList = async (name) => {
      try {
        const res = await request("getContentPage", {
          name: name,
          pageNum: 1,
          pageSize: 20,
        })
        pageList.value = res.records
      } catch (error) {
        console.error(error)
      }
    }
    const getActivityList = async (name) => {
      try {
        const res = await request("getActivityList", {
          name: name,
          page: 1,
          size: 20,
        })
        pageList.value = res.records
      } catch (error) {
        console.error(error)
      }
    }
    const getForumList = async (name) => {
      try {
        const res = await request("getForumList", {
          title: name,
          page: 1,
          size: 20,
        })
        res.records?.forEach(item => {
          item.name = item.title
        })
        pageList.value = res.records
      } catch (error) {
        console.error(error)
      }
    }
    const onImageChange = (file) => {
      console.log('onImageChange -->', file)
      state.detailform.image = file.raw
    }
    const onImageRemove = () => {
      state.detailform.image = undefined
    }
    const onImagePreview = (item) => {
      console.log('onImagePreview', item)
      if (item) {
        state.viewerList = state.imageList.map(item => item.url)
        state.viewerIndex = state.viewerList.indexOf(item.url)
      }
    }
    const addNew = () => {
      state.dialogFormVisible = true
      Object.keys(state.detailform).forEach(key => {
        state.detailform[key] = undefined
      })
      state.imageList = []
    }
    const editItem = (row) => {
      state.dialogFormVisible = true
      Object.keys(state.detailform).forEach(key => {
        state.detailform[key] = row[key]
      })
      state.imageList = row.imageUrl ? [{ name: row.imagePath, url: row.imageUrl }] : []
      state.detailform.associatePageId = state.detailform.associatePage
      state.detailform.image = row.imageUrl
      state.detailform.dateRange = [row.startTime, row.endTime]
      getPageList(state.detailform.associatePage)
    }
    const onFormSubmit = async () => {
      try {
        console.log('onFormSubmit', state.detailform)
        state.detailformRef && await state.detailformRef.validate()
        state.detailform.startTime = state.detailform.dateRange[0]
        state.detailform.endTime = state.detailform.dateRange[1]
        const data = new FormData()
        Object.keys(state.detailform).forEach((key) => {
          state.detailform[key] && data.append(key, state.detailform[key])
        })
        if (!(state.detailform.image instanceof File)) {
          data.delete('image')
        }

        await request('editBanner', data)
        getData()
        state.dialogFormVisible = false
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }
    const editStatus = async (row, status) => {
      try {
        await service({
          url: urlMap.shelfBanner + row.id,
          data: {
            offShell: status
          },
          method: 'DELETE',
        })

        ElMessage({
          showClose: true,
          type: "success",
          message: "操作成功!"
        })
        getData()
      } catch (error) {
        console.error(error)
      }
    }
    const disabledDate = (time) => {
      return time.getTime() > Date.now()
    }
    
    const setupInit = () => {
      try {
        getData()
        // request("syncActivity")
      } catch (error) {
         console.error(error)
      }
    }
    setupInit()
    return {
      channelList,
      channelMap,
      pageList,
      ...toRefs(state),
      detailformRules,
      getData,
      getPageList,
      searchData,
      addNew,
      editItem,
      onFormSubmit,
      updatePage,
      disabledDate,
      onChannelChange,
      onPageChange,
      onImageChange,
      onImageRemove,
      onImagePreview,
      editStatus,
    }
  },
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>
