<template>
  <div class="section-container">
    <Table ref="tableRef" :params="params" :request="getData" tooltip-effect="light">
      <el-table-column type="index" label="序号" width="60px" />
      <el-table-column prop="userName" label="姓名" />
      <el-table-column prop="userLabel" label="用户标签" >
        <template #default="{row}">
          {{ row.userLabel == 1 ? '内部用户' : '普通用户' }}
        </template>
      </el-table-column>
      <el-table-column prop="userPhone" label="手机号" />
      <!-- <el-table-column prop="phoneNumber" label="电话">
        <template #default="{row}">
          {{ row.phoneNumber &&  (String(row.phoneNumber).slice(0,3)+'****'+String(row.phoneNumber).slice(7))}}
        </template>
      </el-table-column>
      <el-table-column prop="email" label="邮箱" /> -->
      <el-table-column prop="company" label="公司" />
      <el-table-column prop="score" label="积分" sortable >
        <template #default="{row}">
          <el-button type="text" size="mini" @click="checkDetail(row)">{{row.score}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" sortable ></el-table-column>
      <el-table-column prop="isAdmin" label="是否管理员" >
        <template #default="{row}">{{row.isAdmin?'是':'否'}}</template>
      </el-table-column>
      <el-table-column label="操作" >
        <template #default="{row}">
          <el-button v-if="!row.isAdmin" type="primary" plain size="mini" @click="setAdmin(row)">设置为管理员</el-button>
        </template>
      </el-table-column>
    </Table>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "@/common/mixin";
import { reactive, toRefs, watch } from "vue";
import { useRouter } from 'vue-router'
import Table from "@/components/elp/table";
// import { formatTime } from "../../common/utils";

export default {
  components: {
    Table,
  },
  mixins: [common],
  setup() {
    const router = useRouter()
    const scoreReason = {
      1: ['优秀帖子', '优秀案例', '其它'],
      2: ['恶意刷分', '内容重复', '其它'],
      3: ['优秀帖子', '优秀案例', '其它'],
      4: ['恶意刷分', '内容重复', '其它']
    }
    const eventTypeMap = {
      1: '推荐新用户注册',
      2: '推荐人激励',
      3: '积分调增',
      4: '积分调减',
      5: '管理员激励',
      6: '个人任务',
      100: '默认',
    }
    const state = reactive({
      query: {
        type: 0
      },
      tableRef: null,
      params: [
        {
          key: "userName",
          value: "",
          label: "姓名",
        },
        {
          key: "userPhone",
          value: "",
          label: "手机号",
        },
      ],
      dialogDetailVisible: false,
      detailData: {
        page: 1,
        size: 10,
        total: 0,
        scoreList: []
      },
      dialogFormVisible: false,
      detailformRef: null,
      detailform: {
        userId: '',
        goodsId: '', // 减法商品项目
        project: '', // 积分项
        reason: '', // 调整原因
        score: '', // 分值
        eventRemark: '' // 备注
      },
      goodsList: []
    })
    const detailformRules = reactive({
      project: {
        required: true,
        message: '请选择积分项',
        trigger: 'change',
      },
      reason: {
        required: true,
        message: '请输入调整原因',
        trigger: 'blur'
      },
      score: {
        required: true,
        message: '请输入调整分值',
        trigger: 'blur'
      },
    })

    watch(() => state.detailform.goodsId, (value) => {
      for (let good of state.goodsList) {
        if (good.goodId == value) {
          state.detailform.score = good.score
          return
        }
      }
    })

    const getData = async (params) => {
      return request("getUserScoreList", params)
    };
    const setAdmin = async (row) => {
      try {
        await request("setAdmin", {
          userId: row.userId
        })
        state.tableRef?.update()
      } catch (error) {
        console.error(error);
      }
    }
    const checkDetail = (row) => {
      router.push({ path: '/points/record', query: { id: row.userId, name: row.userName, phoneNumber: row.userPhone } })
    };

    return {
      scoreReason,
      eventTypeMap,
      ...toRefs(state),
      detailformRules,
      getData,
      setAdmin,
      checkDetail,
    };
  },
};
</script>
<style scoped>
.section-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.list-item {
  margin-bottom: 0;
  text-align: left;
}
</style>