<template>
  <div class="section-container">
    <el-tabs class="tabs-container" type="border-card" v-model="activeTab" @tab-click="handleTabClick">
      <el-tab-pane class="tab-pane" label="统计" name="statistics">
        <Table ref="statisticsRef" :params="statistics.params" :request="getStatistics" tooltip-effect="light">
          <el-table-column type="index" label="序号" width="60px" />
          <el-table-column prop="userName" label="姓名" />
          <el-table-column prop="userLabel" label="用户标签" >
            <template #default="{row}">
              {{ row.userLabel == 1 ? '内部用户' : '普通用户' }}
            </template>
          </el-table-column>
          <el-table-column prop="company" label="公司" />
          <el-table-column prop="phoneNumber" label="手机号" />
          <el-table-column prop="updateTime" label="更新时间" sortable="custom" />
          <!-- <el-table-column prop="isAdmin" label="是否管理员" >
            <template #default="{row}">{{row.isAdmin?'是':'否'}}</template>
          </el-table-column> -->
          <el-table-column prop="score" label="推荐得分" sortable="custom" />
          <el-table-column prop="number" label="推荐人数" sortable="custom" >
            <template #default="{row}">
              <el-button type="text" size="mini" @click="checkDetail(row)">{{row.number}}</el-button>
            </template>
          </el-table-column>
        </Table>
      </el-tab-pane>
      <el-tab-pane class="tab-pane" label="明细" name="detail">
        <Table ref="detailRef" :params="detail.params" :request="getDetail" tooltip-effect="light">
          <template #search="{query}">
            <el-form-item>
              <el-button type="primary" @click="exportData(query)">表格导出</el-button>
            </el-form-item>
          </template>
          <el-table-column type="index" label="序号" width="60px" />
          <el-table-column prop="userName" label="姓名" />
          <el-table-column prop="phoneNumber" label="手机号" />
          <el-table-column prop="email" label="邮箱" />
          <el-table-column prop="company" label="公司" />
          <el-table-column prop="registerTime" label="注册时间" sortable="custom" />
          <el-table-column prop="recommendUserName" label="推荐人" />
          <el-table-column prop="recommendPhoneNumber" label="推荐人手机号" />
        </Table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { request, downloadFile } from "@/utils/request";
import { common } from "@/common/mixin";
import { reactive, toRefs } from "@vue/reactivity";
// import { ElMessage } from "element-plus";
import Table from "@/components/elp/table";
// import { formatTime } from "../../common/utils";


export default {
  components: {
    Table,
  },
  mixins: [common],
  setup() {
    const state = reactive({
      activeTab: 'statistics',
      statisticsRef: null,
      statistics: {
        params: [
          {
            key: "userName",
            value: "",
            label: "姓名",
          },
          {
            key: "userPhone",
            value: "",
            label: "手机号",
          }
        ],
      },
      detailRef: null,
      detail: {
        params: [
          {
            key: "userName",
            value: "",
            label: "姓名",
          },
          {
            key: "userPhone",
            value: "",
            label: "手机号",
          },
          {
            key: "recommendUserName",
            value: "",
            label: "推荐人",
          },
          {
            key: "recommendPhoneNumber",
            value: "",
            label: "推荐人手机号",
          },
          {
          type: "Date",
          key: "dateRange",
          value: [],
          label: "注册时间",
          options: {
            "type": "datetimerange",
            "format": "YYYY-MM-DD HH:mm:ss",
            "value-format": "YYYY-MM-DD HH:mm:ss",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期",
            "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
          }
        },
        ],
      }
    })
    const handleTabClick = () => {

    }
    const getStatistics = (params={}) => {
      return request("getRecScoreList", params)
    }
    const getDetail = (params={}) => {
      const query = { ...params }
      if (params.dateRange?.length) {
        query.leftTime = params.dateRange[0]
        query.rightTime = params.dateRange[1]
      } else {
        query.leftTime = undefined
        query.rightTime = undefined
      }
      return request("getRecUserList", query)
    };
    const checkDetail = (row) => {
      state.activeTab = 'detail'
      state.detailRef?.clearData()
      state.detailRef?.search({ userId: row.userId, recommendUserName: row.userName })
    }
    const exportData = async (params={}) => {
      try {
        const query = { ...params }
        query.page = undefined
        query.size = undefined
        if (params.dateRange?.length) {
          query.leftTime = params.dateRange[0]
          query.rightTime = params.dateRange[1]
        } else {
          query.leftTime = undefined
          query.rightTime = undefined
        }
        const res = await downloadFile("exportRecUserList", {}, {
          method: 'GET',
          params: query
        });
        // let blob = new Blob([res],{type: "application/octet-stream"})
        let reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '推荐明细.xlsx'
          a.href = e.target.result
          a.click()
        }
      } catch (error) {
        console.error(error)
      }
    }
    return {
      ...toRefs(state),
      handleTabClick,
      getStatistics,
      getDetail,
      checkDetail,
      exportData
    };
  },
};
</script>
<style scoped>
.section-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.list-item {
  margin-bottom: 0;
  text-align: left;
}
.tabs-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: 100;
}
:deep(.tabs-container .el-tabs__content) {
  flex: 1;
}
.tabs-container .tab-pane {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>