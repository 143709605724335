<template>
  <div class="section-container">
    <Filter :params="params" @search="searchData"></Filter>
    <el-card class="box-card" :body-style="{ flex: 1, overflow: 'auto' }">
      <template #header>
        <div class="card-header">
          <span>商品类别</span>
          <el-button class="button" type="primary" size="small" @click="addNew">添加</el-button>
        </div>
      </template>
      <div class="el-list">
        <div class="el-list-item" v-for="item in tableDataList" :key="item.id" v-show="item.enable">
          <div class="el-list-item__pre">
            <img :src="item.icon" alt="" srcset="">
          </div>
          <div class="el-list-item__main">
            <div class="el-list-item__block">
              {{item.createTime}}
            </div>
            <div class="el-list-item__block">
              {{item.name}}
            </div>
          </div>
          <div class="el-list-item__suf">
            <el-button size="small" @click="editItem(item)">修改</el-button>
            <el-button type="danger" size="small" @click="deleteItem(item)">删除</el-button>
          </div>
        </div>
      </div>
    </el-card>
    <el-dialog title="新增商品类别" top="5vh" v-model="dialogFormVisible" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="detailformRef" class="dialog-form" :model="detailform" :rules="detailformRules" label-width="120px">
        <el-form-item label="商品种类：" prop="categoryName">
          <el-input v-model="detailform.categoryName" placeholder="商品种类名称" clearable />
        </el-form-item>
        <el-form-item label="图片：" prop="icon">
          <Upload
            v-model="detailform.icon"
            ref="upload"
            :limit="1"
            list-type="picture-card"
            type="image"
            :auto-upload="true"
          >
            <i class="el-icon-plus"></i>
          </Upload>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" type="primary" @click="formSubmit">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "@/common/mixin";
import { reactive, toRefs } from "@vue/reactivity";
import { ElMessage, ElMessageBox } from "element-plus";
// import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
// import { formatTime } from "../../common/utils";
import { urlMap } from '@/common/config';
import Upload from '@/components/upload'

export default {
  components: {
    Filter,
    Upload
  },
  mixins: [common],
  setup() {
    const state = reactive({
      query: {},
      page: 1,
      size: 10,
      total: 0,
      dialogFormVisible: false,
      tableDataList: [],
      detailformRef: null,
      detailform: {
        categoryName: "",
        icon: "",
      },
      params: [
        {
          key: "name",
          value: "",
          label: "名称",
        }
      ],
      activeTabs: 'detail',
      upload: null,
      uploadFile: urlMap['uploadFile']
    })
    const detailformRules = reactive({
      categoryName: {
        required: true,
        message: '请输入商品种类名称',
        trigger: 'blur',
      },
      icon: {
        required: true,
        message: '请上传商品种类图片',
        trigger: 'blur',
      },
    })
    const getData = async () => {
      try {
        const res = await request("getCategoryList", {
          ...state.query,
          page: state.page,
          size: state.size,
        });
        state.tableDataList = res
        // state.tableDataList = res.records;
        // state.total = res.total;
      } catch (error) {
        console.error(error);
      }
    };
    const searchData = async (data) => {
      state.query = data;
      getData()
    };
    const updatePage = (page, size) => {
      state.page = page || 1;
      state.size = size || 10;
      getData();
    };
    const handleTabClick = (tab, event) => {
      console.log(tab,event)
    }
    const uploadSuccess = (response) => {
      console.log('uploadSuccess', response)
      state.detailform.icon = response.data.filePath
    }
    const formSubmit = async () => {
      try {
        state.detailformRef && await state.detailformRef.validate()
        const url = state.detailform.id ? 'updateCategory' : 'addCategory'
        await request(url, state.detailform)
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
        state.dialogFormVisible = false
        getData()
      } catch (error) {
        console.error(error)
      }
    }
    const deleteItem = async (item) => {
      try {
        await ElMessageBox.confirm(
          '确定删除？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        await request("deleteCategory", {
          id: item.id
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "删除成功!"
        })
        getData()
      } catch (error) {
        console.error(error)
      }
    }
    const addNew = () => {
      state.dialogFormVisible=true
      state.detailform = {
        categoryName: "",
        icon: "",
      }
    }
    const editItem = (item) => {
      state.dialogFormVisible=true
      state.detailform = {
        id: item.id,
        categoryName: item.name,
        icon: item.icon,
      }
    }
    getData();
    return {
      ...toRefs(state),
      detailformRules,
      getData,
      searchData,
      updatePage,
      handleTabClick,
      uploadSuccess,
      formSubmit,
      deleteItem,
      addNew,
      editItem
    };
  },
};
</script>
<style lang="scss" scoped>
.section-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.box-card {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-list {
  position: relative;
}
.el-list-item {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #e8eaec;
  &:last-child {
    border-bottom:none
  }
  .el-list-item__pre {
    margin-right: 30px;
  }
  .el-list-item__main {
    flex: 1;
    overflow: hidden;
    display: flex;
    .el-list-item__block {
      margin: 5px 20px;
      line-height: 1.5;
      word-break: break-all;
    }
  }
  .el-list-item__suf {
     margin-left: 30px;
  }
}
</style>