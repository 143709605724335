<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData"></Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <el-table-column prop="time" label="培训日期" width="160px" />
      <el-table-column prop="subject" label="培训主题" width="120px" />
      <el-table-column prop="userName" label="客户姓名" width="120px" />
      <el-table-column prop="company" label="公司" width="160px" />
      <el-table-column prop="title" label="职位" width="120px" />
      <el-table-column prop="phoneNumber" label="联系方式" width="120px" />
      <el-table-column prop="needMoreHelp" label="是否需要业务对接"  width="120px" >
        <template #default="{row}">{{row.needMoreHelp ? '是':'否'}}</template>
      </el-table-column>
      <!-- <el-table-column prop="satisfaction" label="满意度" width="160px" /> -->
      <el-table-column prop="suggestion" label="意见反馈" >
        <template #default="{row}">
          <el-popover
            placement="top-start"
            :width="320"
            trigger="hover"
            v-if="row.suggestion"
          >
            <template #default>
              {{row.suggestion}}
            </template>
            <template #reference>
              <span class="multi-line-text">{{row.suggestion}}</span>
            </template>
          </el-popover>
          <template v-else>无</template>
        </template>
      </el-table-column>
      <el-table-column prop="lecturers" label="讲师" >
        <template #default="{row}">{{row.lecturers.map((item)=>{return item.name}).join(', ')}}</template>
      </el-table-column>
    </Table>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRoute } from 'vue-router'
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import { request } from "@/utils/request";
import { common } from "@/common/mixin";

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const route = useRoute()
    const state = reactive({
      qid: route.query.qid || '',
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      params: [
        {
          type: "Date",
          key: "dateRange",
          value: [],
          label: "日期",
          options: {
            "type": "daterange",
            "format": "YYYY-MM-DD",
            "value-format": "YYYY-MM-DD",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期"
          }
        },
        {
          type: "Input",
          key: "subject",
          value: "",
          label: "培训主题"
        }
      ],
    })

    const getData = async () => {
      try {
        const res = await request('getTrainingResult', {
          id: state.qid,
          ...state.query,
          page: state.page,
          size: state.size,
        })
        state.tableDataList = res.records
        state.total = res.total
      } catch (error) {
        console.error(error)
      }
    };
    const searchData = async (data) => {
      state.query = data
      if (data.dateRange && data.dateRange.length>0) {
        state.query.startDate = data.dateRange[0]
        state.query.endDate = data.dateRange[1]
      } else {
        state.query.startDate = ''
        state.query.endDate = ''
      }
      getData()
    }
    const updatePage = (page, size) => {
      state.page = page || 1
      state.size = size || 10
      getData();
    }
    const getVisitName = (type='') => {
      for (let item of state.visitTypeList) {
        if (type === item.id) return item.name
      }
      return ''
    }
    getData();
    return {
      ...toRefs(state),
      getData,
      searchData,
      updatePage,
      getVisitName
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;  
  display: flex;
  flex-direction: column;
}
.multi-line-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
</style>