import list from '/public/list.json'
const provinceList = list[0]
const cityList = list[1]
const areaList = list[2]
console.log('list -->', provinceList, cityList, areaList)
export function getLocation(province, city, area) {
  let provinceName = ''
  let cityName = ''
  let areaName = ''
  if (province) {
    for (let item of provinceList) {
      if (item.id === province) {
        provinceName = item.fullname
        break
      }
    }
  }
  if (city) {
    for (let item of cityList) {
      if (item.id === city) {
        cityName = item.fullname
        break
      }
    }
  }
  if (area) {
    for (let item of areaList) {
      if (item.id === area) {
        areaName = item.fullname
        break
      }
    }
    for (let item of cityList) {
      if (item.id === area) {
        areaName = item.fullname
        break
      }
    }
  }
  return provinceName + cityName + areaName
}