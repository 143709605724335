<template>
  <div class="section-container">
    <Table
      ref="tableRef"
      :params="params"
      :request="getData"
      tooltip-effect="light"
    >
      <el-table-column type="index" label="序号" width="60px" />
      <el-table-column prop="taskName" label="积分项" />
      <el-table-column prop="score" label="累积积分/次" />
      <el-table-column prop="upperLimit" label="上限" />
      <el-table-column prop="pagepath" label="小程序路径" />
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column prop="updateTime" label="更新时间" />
      <el-table-column label="操作" width="150px" fixed="right">
        <template #default="{ row }">
          <!-- <el-button
            type="text"
            text
            @click="checkDetail(row)"
            >查看
          </el-button> -->
          <el-button
            type="primary"
            plain
            size="mini"
            @click="editItem(row)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </Table>
    <el-dialog title="积分任务" top="5vh" v-model="dialogFormVisible" :modal="true" destroy-on-close :append-to-body="true">
      <el-form ref="detailformRef" class="dialog-form" :model="detailform" :rules="detailformRules" label-width="128px">
        <el-form-item label="任务名称：" prop="name">
          <el-input v-model="detailform.name" placeholder="任务名称" clearable />
        </el-form-item>
        <el-form-item label="任务积分：" prop="score">
          <el-input type="number" v-model="detailform.score" placeholder="任务积分" clearable />
        </el-form-item>
        <el-form-item label="任务次数上限：" prop="limit">
          <el-input type="number" v-model="detailform.limit" placeholder="任务次数上限" clearable />
        </el-form-item>
        <el-form-item label="小程序路径：">
          <el-input v-model="detailform.pagepath" placeholder="/pages/index/index" clearable />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" type="primary" @click="formSubmit">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "@/common/mixin";
import { reactive, toRefs } from "@vue/reactivity";
import { ElMessage } from "element-plus";
import Table from '@/components/elp/table'
// import { formatTime } from "../../common/utils";


export default {
  components: {
    Table
  },
  mixins: [common],
  setup() {
    const state = reactive({
      query: {},
      tableRef: null,
      dialogFormVisible: false,
      tableDataList: [],
      detailformRef: null,
      detailform: {
        id: '',
        name: "",
        score: 0,
        pagepath: '',
        limit: 0
      },
      params: [
        {
          key: "taskName",
          value: "",
          label: "积分项",
        },
      ],
      activeTabs: 'detail'
    })
    const detailformRules = reactive({
      name: {
        required: true,
        message: '请输入任务名称',
        trigger: 'blur',
      },
      score: {
        required: true,
        message: '请输入积分数量',
        trigger: 'blur',
        validator(rule, value) {
          return value > 0
        }
      },
      limit: {
        required: true,
        message: '请输入任务次数上限',
        trigger: 'blur',
        validator(rule, value) {
          return value > 0
        }
      },
    })
    const getData = async (params) => {
      return request("getTaskList", params)
    }
    const formSubmit = async () => {
      try {
        state.detailformRef && await state.detailformRef.validate()
        const url = state.detailform.id ? 'updateTask' : 'addTask'
        await request(url, state.detailform)
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
        state.dialogFormVisible = false
        state.tableRef?.update()
      } catch (error) {
        console.error(error)
      }
    }
    const editItem = (item) => {
      state.dialogFormVisible=true
      state.detailform = {
        id: item.taskId,
        name: item.taskName,
        score: item.score,
        pagepath: item.pagepath,
        limit: item.upperLimit
      }
    }
    return {
      ...toRefs(state),
      detailformRules,
      getData,
      formSubmit,
      editItem
    };
  },
};
</script>
<style lang="scss" scoped>
.section-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.box-card {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-list {
  position: relative;
}
.el-list-item {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #e8eaec;
  &:last-child {
    border-bottom:none
  }
  .el-list-item__pre {
    margin-right: 10px;
    min-width: 200px;
  }
  .el-list-item__main {
    flex: 1;
    overflow: hidden;
    display: flex;
    .el-list-item__block {
      margin: 5px 20px;
      word-break: break-all;
    }
  }
  .el-list-item__suf {
    margin-left: 20px;
  }
}
</style>