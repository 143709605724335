<template>
  <div class="section-container">
    <Table
      ref="tableRef"
      :params="params"
      :request="getData"
      add
      @add="addNew"
      tooltip-effect="light"
    >
      <el-table-column type="index" label="序号" width="60px" />
      <el-table-column prop="name" label="商品名称" width="200px" >
        <template #default="{ row }">
          <div style="display: flex;">
            <img :src="row.icon" style="width:100px;height:100px;margin-right: 10px;" alt="" srcset="">
            <span style="flex:1 ;">{{ row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="categoryName" label="商品分类"></el-table-column>
      <el-table-column prop="score" label="积分" />
      <el-table-column prop="stockNumber" label="可兑数量" >
        <template #default="{ row }">{{ row.stockNumber || 0 }}</template>
      </el-table-column>
      <el-table-column prop="quantityRedeemed" label="已兑数量" >
        <template #default="{ row }">{{ row.quantityRedeemed || 0 }}</template>
      </el-table-column>
      <el-table-column prop="goodStatus" label="当前状态">
        <template #default="{ row }">{{ goodStatusMap[row.goodStatus] }}</template>
      </el-table-column>
      <el-table-column label="操作" width="210px" fixed="right">
        <template #default="{ row }">
          <el-button
            v-if="row.goodStatus == 2"
            type="primary"
            plain
            size="mini"
            @click="showOnShelf(row)"
            >上架
          </el-button>
          <el-button
            v-if="row.goodStatus == 1"
            type="primary"
            plain
            size="mini"
            @click="offShelfGoods(row)"
            >下架</el-button
          >
          <el-button
            v-if="row.goodStatus == 2"
            type="primary"
            plain
            size="mini"
            @click="showEditGoods(row)"
            >编辑</el-button
          >
          <el-button v-if="row.goodStatus == 2" type="danger" plain size="mini" @click="deleteGoods(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </Table>
    <el-dialog
      title="新增商品"
      top="5vh"
      v-model="dialogFormVisible"
      destroy-on-close
      :modal="true"
      :append-to-body="true"
    >
      <el-form
        ref="detailformRef"
        class="dialog-form"
        :model="detailform"
        :rules="detailformRules"
        label-width="120px"
      >
        <el-form-item label="商品种类：" prop="categoryId">
          <el-select
            v-model="detailform.categoryId"
            placeholder="商品种类"
            clearable
          >
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="商品名称：" prop="name">
          <el-input
            v-model="detailform.name"
            placeholder="商品名称（不大于20个字符）"
            clearable
          />
        </el-form-item>
        <el-form-item label="商品积分：" prop="score">
          <el-input-number
            v-model="detailform.score"
            :min="1"
            :step="1"
            controls-position="right"
          />
        </el-form-item>
        <el-form-item label="商品描述：" prop="description">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4 }"
            placeholder="商品描述"
            v-model="detailform.description"
          />
        </el-form-item>
        <el-form-item label="图片：" prop="icon">
          <Upload
            v-model="detailform.icon"
            :limit="1"
            list-type="picture-card"
            type="image"
            :auto-upload="true"
          >
            <i class="el-icon-plus"></i>
          </Upload>
        </el-form-item>
        <el-form-item label="商品数量：" prop="stockNumber">
          <el-input-number
            v-model="detailform.stockNumber"
            :min="1"
            :step="1"
            controls-position="right"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" type="primary" @click="formSubmit"
          >确认</el-button
        >
      </template>
    </el-dialog>
    <el-dialog
      title="上架商品"
      top="5vh"
      v-model="dialogUploadVisible"
      destroy-on-close
      :modal="true"
      :append-to-body="true"
    >
      <el-form ref="uploadFormRef" :model="uploadForm" class="dialog-form" label-width="120px">
        <el-form-item label="商品名称：">{{ detailform.name }}</el-form-item>
        <el-form-item label="商品类型：">{{
          detailform.categoryName
        }}</el-form-item>
        <el-form-item label="可兑换时间：" prop="exchangeStartTime" :rules="[{ required: true, message: '请选择可兑换时间', trigger: 'change' }]">
          <el-date-picker
            v-model="exchangeRange"
            type="datetimerange"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :default-time="[
              new Date(2000, 1, 1, 0, 0, 0),
              new Date(2000, 1, 1, 23, 59, 59),
            ]"
          />
        </el-form-item>
        <el-form-item label="发货方式：" prop="deliveryMethod" :rules="[{ required: true, message: '请选择发货方式', trigger: 'change' }]">
          <el-radio-group v-model="uploadForm.deliveryMethod">
            <el-radio :label="1">快递</el-radio>
            <el-radio :label="2">联系在线客服</el-radio>
            <el-radio :label="3">现场核销</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" type="primary" @click="onShelfGoods"
          >确认</el-button
        >
      </template>
    </el-dialog>
    <el-dialog
      title="修改商品"
      top="5vh"
      v-model="dialogEditVisible"
      destroy-on-close
      :modal="true"
      :append-to-body="true"
    >
      <el-form ref="editFormRef" :model="editForm" :rules="editFormRules" class="dialog-form" label-width="120px">
        <el-form-item label="商品名称：" prop="goodsName">
          <el-input
            v-model="editForm.goodsName"
            placeholder="商品名称（不大于20个字符）"
            clearable
          />
        </el-form-item>
        <el-form-item label="商品类型：">{{
          detailform.categoryName
        }}</el-form-item>
        <el-form-item label="商品描述：" prop="description">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4 }"
            placeholder="商品描述"
            v-model="editForm.description"
          />
        </el-form-item>
        <el-form-item label="商品积分：" prop="score">
          <el-input-number
            v-model="editForm.score"
            :min="1"
            :step="1"
            controls-position="right"
          />
        </el-form-item>
        <el-form-item label="商品数量：" prop="stockNumber">
          <el-input-number
            v-model="editForm.stockNumber"
            :min="1"
            :step="1"
            controls-position="right"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" type="primary" @click="editGoods"
          >确认</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { request } from '@/utils/request'
import { common } from '@/common/mixin'
import { ref, reactive, toRefs } from '@vue/reactivity'
import { ElMessage, ElMessageBox } from 'element-plus'
import Table from '@/components/elp/table'
// import { formatTime } from "../../common/utils";
import Upload from '@/components/upload'

export default {
  components: {
    Upload,
    Table,
  },
  mixins: [common],
  setup() {
    const goodStatusMap = {
      1: '已上架',
      2: '待上架',
    }
    const goodStatusList = Object.entries(goodStatusMap).map(item => {
      return {
        label: item[1],
        value: Number(item[0])
      }
    })
    const categoryList = ref([])
    const state = reactive({
      tableRef: null,
      dialogFormVisible: false,
      detailformRef: null,
      detailform: {
        categoryId: '',
        categoryName: '',
        icon: '',
        name: '',
        score: 0,
        description: '',
        stockNumber: 0
      },
      params: [
        {
          type: 'Select',
          key: 'categoryName',
          value: '',
          label: '商品分类',
          options: {
            data: categoryList,
            label: 'name',
            value: 'name',
          },
        },
        {
          key: 'name',
          value: '',
          label: '商品名称',
        },
        {
          type: 'Select',
          key: 'goodStatus',
          value: 1,
          label: '商品状态',
          options: {
            data: goodStatusList,
            label: 'label',
            value: 'value',
          },
        },
      ],

      dialogEditVisible: false,
      editFormRef: null,
      editForm: {
        id: undefined,
        goodsName: '',
        description: '',
        score: undefined,
        stockNumber: undefined,
      },
      dialogUploadVisible: false,
      exchangeRange: [],
      uploadFormRef: null,
      uploadForm: {
        id: undefined,
        deliveryMethod: undefined,
        exchangeStartTime: undefined,
        exchangeEndTime: undefined,
      },
    })
    const detailformRules = reactive({
      categoryId: {
        required: true,
        message: '请选择商品种类',
        trigger: 'change',
      },
      name: [{
        required: true,
        message: '请输入商品名称',
        trigger: 'blur',
      },{
        message: '商品名称不能大于20个字符',
        trigger: 'blur',
        max: 20
      }],
      score: {
        required: true,
        message: '请输入积分数量',
        trigger: 'blur',
        validator(rule, value) {
          return value > 0
        },
      },
      description: {
        required: true,
        message: '请输入商品描述',
        trigger: 'blur',
      },
      icon: {
        required: true,
        message: '请上传商品图片',
        trigger: 'change',
      },
      stockNumber: {
        required: true,
        message: '请输入库存数量',
        trigger: 'blur',
        validator(rule, value) {
          return value > 0
        },
      },
    })
    const editFormRules = reactive({
      goodsName: [{
        required: true,
        message: '请输入商品名称',
        trigger: 'blur',
      },{
        message: '商品名称不能大于20个字符',
        trigger: 'blur',
        max: 20
      }],
      description: {
        required: true,
        message: '请输入商品描述',
        trigger: 'blur',
      },
      score: {
        required: true,
        message: '请输入积分数量',
        trigger: 'blur',
        validator(rule, value) {
          return value > 0
        },
      },
      stockNumber: {
        required: true,
        message: '请输入库存数量',
        trigger: 'blur',
        validator(rule, value) {
          return value > 0
        },
      },
    })
    const getData = async (params) => {
      return request('getGoodsList', params)
    }
    const getCategoryList = async () => {
      try {
        const res = await request('getCategoryList')
        categoryList.value = res.filter((item) => {
          return item.enable
        })
        // state.tableDataList = res.records;
        // state.total = res.total;
      } catch (error) {
        console.error(error)
      }
    }
    const uploadSuccess = (response) => {
      console.log('uploadSuccess', response)
      state.detailform.icon = response.data.filePath
    }
    const formSubmit = async () => {
      try {
        state.detailformRef && (await state.detailformRef.validate())
        await request('addGoods', state.detailform)
        ElMessage({
          showClose: true,
          type: 'success',
          message: '提交成功!',
        })
        state.dialogFormVisible = false
        state.tableRef?.update()
      } catch (error) {
        console.error(error)
      }
    }
    const addNew = () => {
      state.dialogFormVisible = true
      state.detailform = {
        categoryId: '',
        categoryName: '',
        icon: '',
        name: '',
        score: 0,
        description: '',
        stockNumber: 0
      }
    }
    const showEditGoods = (row) => {
      state.dialogEditVisible = true
      state.editForm = {
        id: row.id,
        goodsName: row.name,
        description: row.description,
        score: row.score,
        stockNumber: row.stockNumber,
      }
      state.detailform = row
    }
    const showOnShelf = (row) => {
      state.dialogUploadVisible = true
      state.uploadForm = {
        id: row.id,
        deliveryMethod: row.deliveryMethod,
        exchangeStartTime: row.exchangeStartTime,
        exchangeEndTime: row.exchangeEndTime,
      }
      state.exchangeRange = [row.exchangeStartTime, row.exchangeEndTime]
      state.detailform = row
      
    }
    const onShelfGoods = async () => {
      try {
        state.uploadForm.exchangeStartTime = state.exchangeRange[0]
        state.uploadForm.exchangeEndTime = state.exchangeRange[1]
        await state.uploadFormRef.validate()
        await request('onShelfGoods', state.uploadForm)
        state.dialogUploadVisible = false
        ElMessage({
          showClose: true,
          type: 'success',
          message: '上架成功!',
        })
        state.tableRef?.update()
      } catch (error) {
        console.error(error)
      }
    }
    const offShelfGoods = async (row) => {
      try {
        await ElMessageBox.confirm('确定下架？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        await request('offShelfGoods', {
          id: row.id,
        })
        ElMessage({
          showClose: true,
          type: 'success',
          message: '下架成功!',
        })
        state.tableRef?.update()
      } catch (error) {
        console.error(error)
      }
    }
    const editGoods = async () => {
      try {
        await state.editFormRef.validate()
        await request('updateGoods', state.editForm)
        state.dialogEditVisible = false
        ElMessage({
          showClose: true,
          type: 'success',
          message: '提交成功!',
        })
        state.tableRef?.update()
      } catch (error) {
        console.error(error)
      }
    }
    const deleteGoods = async (row) => {
      try {
        await ElMessageBox.confirm('确定删除？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        await request('deleteGoods', {
          id: row.id,
        })
        ElMessage({
          showClose: true,
          type: 'success',
          message: '删除成功!',
        })
        state.tableRef?.update()
      } catch (error) {
        console.error(error)
      }
    }
    getCategoryList()
    return {
      goodStatusMap,
      categoryList,
      ...toRefs(state),
      detailformRules,
      editFormRules,
      getData,
      uploadSuccess,
      formSubmit,
      addNew,
      showOnShelf,
      onShelfGoods,
      offShelfGoods,
      showEditGoods,
      editGoods,
      deleteGoods,
    }
  },
}
</script>
<style lang="scss" scoped>
.section-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.box-card {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-list {
  position: relative;
}
.el-list-item {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #e8eaec;
  &:last-child {
    border-bottom: none;
  }
  .el-list-item__pre {
    margin-right: 30px;
  }
  .el-list-item__main {
    flex: 1;
    overflow: hidden;
    display: flex;
    .el-list-item__block {
      margin: 5px 20px;
      word-break: break-all;
    }
  }
  .el-list-item__suf {
    margin-left: 30px;
  }
}
</style>
